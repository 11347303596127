.gsui-page-panel {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .page-title {
      font-size: $font-size-28;
      padding: 0 0 15px 0;
    }
    .actions {
      text-align: right;
      button {
        margin-left: 10px;
      }
    }
  }
  .page-desc {
    font-size: 14px;
    color: #666;
    margin-top: -15px;
    padding-bottom: 10px;
  }
}
