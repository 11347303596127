.gsui-tiles-wrap {
  display: flex;
  padding: 0px;
  &.row {
    flex-direction: column;
    label {
      min-height: 80px;
      &:first-child {
        margin-right: 0px;
      }
      &:last-child {
        margin-left: 0px;
      }
    }
  }
  label {
    flex: 1;
    padding: 8px 16px;
    border: 1px solid #aab7b8;
    cursor: pointer;
    .name {
      vertical-align: middle;
      font-size: $font-size-base;
      span {
        display: inline-block;
        margin-top: -2px;
        padding-left: 5px;
      }
    }
    .desc {
      font-size: $font-size-12;
      color: #687078;
      padding-left: 17px;
    }
    &.disabled {
      cursor: not-allowed;
      background-color: #e9ebed;
      color: #9ba7b6;
      .desc {
        color: #9ba7b6;
      }
    }
    &:first-child {
      margin-right: 8px;
    }
    &:last-child {
      margin-left: 8px;
    }
    &.active {
      border: 1px solid #0073bb;
      background: #f1faff;
    }
  }
}
